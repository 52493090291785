
import Base from "frontendframework/dist/javascripts/base";
import BodyScriptActivator from "frontendframework/dist/javascripts/body_script_activator";
import ObjectLifeCycle from "frontendframework/dist/javascripts/enumerations/object_life_cycle";
import * as PubSub from "frontendframework/dist/javascripts/pub_sub";
import Runtime from "frontendframework/dist/javascripts/runtime";
import ICourseDiggersGlobalHandle from "./common/i_course_diggers_global_handle";
import * as Utils from "./common/utils";
import * as ShowPageChart from "./charts/show";
import setupForm from "./forms/review"
import $ from "jquery";
import * as ExploreTable from "./tables/explore";
import barChart from "./charts/bar_chart";
import Application from "./configuration/application";

// Use strict mode
// tslint:disable-next-line:no-unused-expression
"use strict";

(window as any).Application = Application.getInstance();

/*
Session Storage KEY-VAL pairs

PRIORITY 1 (these are vital to be able to set):

* SCHOOLS_SELECT      -> number
  - Stores the ID of the selected school
* hide-js-iancmnt     -> bool
  - Stores a boolean which determines whether or not to show announcement box on home page
* EXPLORE_TABLE_STATE -> object (TODO: Implement this)
  - Stores state of user explore table configuration (like what column they are sorting by
    and whether to sort in ascending or descending order


PUB SUB system subscription identifiers

PRIORITY 1 (there are publish and subscribe events across application for these subscription identifiers)

* 'pubsubscription:selected_school'
  - For relaying information about selected school


GLOBAL Variables

PRIORITY 1 (these are utilized in code across application)
* ga                 -> Google Analytics Object
  - Necessary for all Google Analytics tracking code (DO NOT OVERWRITE THIS UNDER ANY CIRCUMSTANCES!!!)

PRIORITY 2 (used in a single page (persistence of these variables outside of their
page should be considered a memory leak (materiality of leak depends on size of
 variable in memory)))

*/

/*
PUB SUB system usage notes (FrontEndFramework 0.6.5):

There is only one item that needs to be synchronized with this system. That is the select school.

Main priorities of refactoring:
- Stop relying on the non-scalale workaround function `broadcastSchoolChange`.
  - This will facilitate adding pages to application without having to modify the workaround.
- Remove global `SCHOOLS_SELECT` variable.

*/


////////////////////////////
// Code to Refactor Later //
////////////////////////////

// Register school select HTMLInputElement with PubSub system
BodyScriptActivator.getInstance().AddEntryToLookupTable('#js-home-search-school-select', function() {
    new PubSub.HtmlInputElementPublisherAndSubscriber(
        'pubsubscription:selected_school', // subscription identifier
        'js-home-search-school-select', // HTML id
        function() {
            // On school selection get search space (json) from server
            //   Also if no selection, make text input for search query readonly / disabled; otherwise enable text input box
            // On typing in search input box, give search results
            if ((document.getElementById('js-home-search-school-select') as HTMLSelectElement).value === '') { // empty school selection
                $('#js-home-search-input').prop('disabled', true);
                $('#js-home-search-glyph').addClass('grey');
            } else {
                $.getJSON(
                    ('/pages/search_space?school_id=' + (document.getElementById('js-home-search-school-select') as HTMLSelectElement).value),
                    function(json) {
                        ($('#js-home-search-input') as any).autocomplete({
                            lookup: json,
                            lookupFilter: function (suggestion:any, _originalQuery:any, queryLowerCase:any) {
                                return suggestion.key.indexOf(queryLowerCase) !== -1;
                            },
                            lookupLimit: 50,
                            beforeRender: function(container:any) { container.attr("id", "js-home-page-search-suggestions"); },
                            onSelect: function (suggestion:any) {
                                Runtime.visitLink('/pages/show?utf8=%E2%9C%93&digger%5Bcourse_id%5D='  + suggestion.data + '&commit=GO');
                            }
                        });
                        $('#js-home-search-input').prop('disabled', false);
                        $('#js-home-search-glyph').removeClass('grey');
                    }
                );
            }
        }
    );
});

// De-activate since, not used?
BodyScriptActivator.getInstance().AddEntryToLookupTable('#banner', function() {
   $(document).ready(function(){
     $("#banner").toggleClass("hidden").slideUp(1).delay(100).slideDown(2000);
   });
 });


BodyScriptActivator.getInstance().AddEntryToLookupTable('#missing-course-form-enable', function() {
    // Set by updateGlobalFormVars
    var schoolSelection = '';
    var checkmarkSelection = false;

    // Not set by updateGlobalFormVars
    var schoolSelectionOptionTextBackup = '';
    var schoolSelectionOptionTextBackupLockedP = false;

    // const String // (should be treated as immutable (never change this))
    var SchoolSelectionDisableOptionText = 'Fill in form below'

    // Note all functions defined here have side effects

    // void updateGlobalFormVars()
    var updateGlobalFormVars = function() {
        schoolSelection = document.forms[0]['missing_course[school_id]'].value;
        checkmarkSelection = document.forms[0]['missing_course[school_not_in_list]'][1].checked;
    }

    // boolean setSchoolSelectionDisability(boolean disableP)
    var setSchoolSelectionDisability = function(disableP:boolean) {
        if (disableP) {
            $('#' + document.forms[0]['missing_course[school_id]'].id + ' option:selected').text(SchoolSelectionDisableOptionText);
            schoolSelectionOptionTextBackupLockedP = true;
            document.forms[0]['missing_course[school_id]'].disabled = true;
        } else {
            document.forms[0]['missing_course[school_id]'].disabled = false;
            $('#' + document.forms[0]['missing_course[school_id]'].id + ' option:selected').text(schoolSelectionOptionTextBackup);
            schoolSelectionOptionTextBackupLockedP = false;
        }
    }

    // void toggleVisibility()
    var toggleVisibility = function() {
        updateGlobalFormVars();
        if (checkmarkSelection) {
            if (!document.forms[0]['missing_course[school_id]'].disabled)
                setSchoolSelectionDisability(true);
            $('#no-school-path').slideDown();
            $('#show-when-selection-possible').slideDown();
        } else {
            if (document.forms[0]['missing_course[school_id]'].disabled)
                setSchoolSelectionDisability(false);
            $('#no-school-path').slideUp();
            if (schoolSelection === '') {
                $('#show-when-selection-possible').slideUp();
            } else {
                $('#show-when-selection-possible').slideDown();
            }
        }
    }

    if (!schoolSelectionOptionTextBackupLockedP)
        schoolSelectionOptionTextBackup = $('#' + document.forms[0]['missing_course[school_id]'].id + ' option:selected').text();
    toggleVisibility();
    $('#missing_course_school_id').change(function() {
        if (!schoolSelectionOptionTextBackupLockedP)
            schoolSelectionOptionTextBackup = $('#' + document.forms[0]['missing_course[school_id]'].id + ' option:selected').text();
        toggleVisibility();
    });
    $('#missing_course_school_not_in_list').change(toggleVisibility);
});


BodyScriptActivator.getInstance().AddEntryToLookupTable('#show-page-not-nil-course', function () {
    const schoolId = $('#_show_course_department_school_id').val();
    const schoolName = $('#_show_course_department_school_name').val();
    PubSub.publish('pubsubscription:selected_school', schoolId);
    ((window as any).ga as any)('send', {
        hitType: 'event',
        eventCategory: 'Show Page School',
        eventAction: 'Visit',
        eventLabel: schoolName,
        eventValue: schoolId
    });
});

BodyScriptActivator.getInstance().AddEntryToLookupTable('#show-page-course-grades-available', function() {
    const courseId = $('#_show_course_id').val();
    google.charts.setOnLoadCallback(function() {
        $.getJSON(`/data/${courseId}.json`, function(json) {
            console.info("Building advanced stats binding");
            ShowPageChart.generateAdvancedStatsBinding(json);
        }).fail(function(_jqxhr, textStatus, error) {
            var err = textStatus.toUpperCase() + ": " + error;
            console.error(err);
        });
    });
});

BodyScriptActivator.getInstance().AddEntryToLookupTable('#js-avg-row-filter-enable', function() {
    let inProgress = false;
    $('#js-avg-row-filter').click(function(_event) {
        if (inProgress) { return; }
        inProgress = true;
        $('#js-row-filter-btn').click();
        inProgress = false;
    });
});

BodyScriptActivator.getInstance().AddEntryToLookupTable('#admin-reviews-over-time-chart-enable', function() {
    $.getJSON(`/admin/daily_review_data.json`, function(json) {
        console.info("Generating chart b34efec4-c1bc-41f3-9fbf-b5e3a6d71b5f");
        barChart('#admin-reviews-over-time-chart', json);
    }).fail(function(_jqxhr, textStatus, error) {
        var err = textStatus.toUpperCase() + ": " + error;
        console.error(err);
    });
});

 BodyScriptActivator.getInstance().AddEntryToLookupTable('#_pages_explore_gpa_boosters_table-enable', function() {
     function ExploreTableRow(this: ExploreTable.IExploreTableRow, courseId:number, courseName:string, difficulty:number, workload:number, dig:number) {
         this.courseId = courseId; // Integer
         this.courseName = courseName; // String
         this.difficulty = difficulty; // Float
         this.workload = workload; // Float
         this.dig = dig; // Float
         this.events_setup_p = false;

         this.generateRow = function(x:any) {
             var tr = '<tr id="explore-table-row-' + this.courseId + '" class="explore-row' + (((x + 1) % 2 === 0) ? ' explore-even' : '') +
                 '" onclick="window.open(' + "'/pages/show?utf8=✓&digger%5Bcourse_id%5D=" +
                 this.courseId + "&commit=GO', '_blank')" + '">';
             tr = tr + "<td>" + this.courseName +  "</td>";
             tr = tr + "<td>" + this.difficulty.toFixed(1) +  "</td>";
             tr = tr + "<td>" + this.workload.toFixed(1) +  "</td>";
             tr = tr + "<td>" + (this.dig * 100).toFixed(1) +  "</td>";
             return (tr + "</tr>");
         };

         this.setupEventHandlers = function() {
             // Destroy existing handlers
             var thiz = this;
             ($ as any).contextMenu('destroy', ('#explore-table-row-' + thiz.courseId));
             ($ as any).contextMenu({
                 selector: ('#explore-table-row-' + thiz.courseId),
                 callback: function(key:any, _options:any) {
                     // var m = "clicked: " + key;
                     // window.console && console.log(m) || alert(m);
                     // console.info(options);
                     switch (key) {
                         case 'Open':
                             Runtime.visitLink('/pages/show?utf8=✓&digger%5Bcourse_id%5D=' + thiz.courseId + '&commit=GO');
                             break;
                         case 'Open in New Tab':
                             window.open("/pages/show?utf8=✓&digger%5Bcourse_id%5D=" + thiz.courseId + "&commit=GO", "_blank");
                             break;
                         default:
                             break;
                     }
                 },
                 items: {
                     "Open": {name: "Open", icon: function(_opt:any, $itemElement:any, itemKey:any, _item:any){
                         //$itemElement.removeClass('context-menu-item');
                         $itemElement.html('<span style="font-family: verdana;">' + itemKey + '</span>')
                     }},
                     "Open in New Tab": {name: "Open in New Tab", icon: function(_opt:any, $itemElement:any, itemKey:any, _item:any){
                         //$itemElement.removeClass('context-menu-item');
                         $itemElement.html('<span style="font-family: verdana;">' + itemKey + '</span>')
                     }}
                 }
             });
         };
     }

     Base.getInstance().gHndl.ExploreTableRow = ExploreTableRow;
     Base.getInstance().gHndl.rows = [];

     var rowSortingStates = { // State transitions: 0 -> 1 -> 2 -> 1
         courseName: 0,     // 0: Not sorted
         difficulty: 1, // 1: Sorted ascending
         workload: 0,  // 2: Sorted descending
         dig: 0
     }; // Note: a column being sorted should send all other columns' states to 0
     var setRowSortingStates = function(sorting_id:number) {
         console.log(sorting_id);
         switch(sorting_id) {
             case 0: // id
                 rowSortingStates.courseName = ((rowSortingStates.courseName < 2) ? (rowSortingStates.courseName + 1) : 1);
                 rowSortingStates.difficulty = 0;
                 rowSortingStates.workload = 0;
                 rowSortingStates.dig = 0;
                 break;
             case 1: // difficulty
                 rowSortingStates.courseName = 0;
                 rowSortingStates.difficulty = ((rowSortingStates.difficulty < 2) ? (rowSortingStates.difficulty + 1) : 1);
                 rowSortingStates.workload = 0;
                 rowSortingStates.dig = 0;
                 break;
             case 2: // workload
                 rowSortingStates.courseName = 0;
                 rowSortingStates.difficulty = 0;
                 rowSortingStates.workload = ((rowSortingStates.workload < 2) ? (rowSortingStates.workload + 1) : 1);
                 rowSortingStates.dig = 0;
                 break;
             case 3: // dig
                 rowSortingStates.courseName = 0;
                 rowSortingStates.difficulty = 0;
                 rowSortingStates.workload = 0;
                 rowSortingStates.dig = ((rowSortingStates.dig < 2) ? (rowSortingStates.dig + 1) : 1);
                 break;
             default: // other
                 return; // Short-circuit
         }
         console.log(rowSortingStates);
     };
     // Inputs should be "integers" to select nth-child css selectors
     var setColumnFormatting = function(toSelect:any) {
         var columns = [1, 2, 3, 4];
         // Breaks IE8 and below :(
         var index = columns.indexOf(toSelect);
         columns.splice(index, 1);

         columns.forEach(function(curColumn:any, _index:any, _arr:any) {
             $('.gpa_boosters_table thead th:nth-child(' + curColumn + ')').css('color', 'rgb(51, 51, 51)');
             $('.gpa_boosters_table thead th:nth-child(' + curColumn + ')').css('border-top', '3px solid rgb(238, 238, 238)');
             // The Opx in the two css styles below matters since the left border of the selected th will not be seen otherwise
             $('.gpa_boosters_table thead th:nth-child(' + curColumn + ')').css('border-left', '0px solid rgb(238, 238, 238)');
             $('.gpa_boosters_table thead th:nth-child(' + curColumn + ')').css('border-right', '0px solid rgb(238, 238, 238)');
             $('.gpa_boosters_table tbody td:nth-child(' + curColumn + ')').css('border-left', '1px solid rgb(153, 153, 153)');
             $('.gpa_boosters_table tbody td:nth-child(' + curColumn + ')').css('border-right', '1px solid rgb(153, 153, 153)');
         });
         $('.gpa_boosters_table thead th:nth-child(' + toSelect + ')').css('color', 'red');
         $('.gpa_boosters_table thead th:nth-child(' + toSelect + ')').css('border-top', '3px solid rgb(240, 202, 0)');
         $('.gpa_boosters_table thead th:nth-child(' + toSelect + ')').css('border-left', '3px solid rgb(240, 202, 0)');
         $('.gpa_boosters_table thead th:nth-child(' + toSelect + ')').css('border-right', '3px solid rgb(240, 202, 0)');
         $('.gpa_boosters_table tbody td:nth-child(' + toSelect + ')').css('border-left', '3px solid rgb(240, 202, 0)');
         $('.gpa_boosters_table tbody td:nth-child(' + toSelect + ')').css('border-right', '3px solid rgb(240, 202, 0)');
     };
     var resetHeaderGlyphicons = function() {
         $('#_v_course_name_header').removeClass('glyphicon-arrow-down');
         $('#_v_course_name_header').removeClass('glyphicon-arrow-up');
         $('#_v_course_name_header').addClass('glyphicon-arrow-up');

         $('#_v_difficulty_header').removeClass('glyphicon-arrow-down');
         $('#_v_difficulty_header').removeClass('glyphicon-arrow-up');
         $('#_v_difficulty_header').addClass('glyphicon-arrow-up');

         $('#_v_workload_header').removeClass('glyphicon-arrow-down');
         $('#_v_workload_header').removeClass('glyphicon-arrow-up');
         $('#_v_workload_header').addClass('glyphicon-arrow-up');

         $('#_v_dig_header').removeClass('glyphicon-arrow-down');
         $('#_v_dig_header').removeClass('glyphicon-arrow-up');
         $('#_v_dig_header').addClass('glyphicon-arrow-up');
     };
     Base.getInstance().gHndl.rowSort = function(cid:any) {
         setRowSortingStates(cid);
         switch(cid) {
             case 0: // courseName
                 Base.getInstance().gHndl.rows = Base.getInstance().gHndl.rows.sort(function(a:any, b:any) {
                     var temp = 0;
                     if (a.courseName < b.courseName) temp = -1;
                     if (a.courseName > b.courseName) temp = 1;
                     return temp * ((rowSortingStates.courseName === 2) ? -1 : 1);
                 });
                 break;
             case 1: // difficulty
                 Base.getInstance().gHndl.rows = Base.getInstance().gHndl.rows.sort(function(a:any, b:any) {
                     var temp = 0;
                     if (a.difficulty < b.difficulty) temp = -1;
                     if (a.difficulty > b.difficulty) temp = 1;
                     return temp * ((rowSortingStates.difficulty === 2) ? -1 : 1);
                 });
                 break;
             case 2: // workload
                 Base.getInstance().gHndl.rows = Base.getInstance().gHndl.rows.sort(function(a:any, b:any) {
                     var temp = 0;
                     if (a.workload < b.workload) temp = -1;
                     if (a.workload > b.workload) temp = 1;
                     return temp * ((rowSortingStates.workload === 2) ? -1 : 1);
                 });
                 break;
             case 3: // dig
                 Base.getInstance().gHndl.rows = Base.getInstance().gHndl.rows.sort(function(a:any, b:any) {
                     var temp = 0;
                     if (a.dig < b.dig) temp = -1;
                     if (a.dig > b.dig) temp = 1;
                     return temp * ((rowSortingStates.dig === 2) ? -1 : 1);
                 });
                 break;
             default: // other
                 console.log('rowSort misfire.');
                 return; // Short-circuit
         }
         clearTable();
         populateTable();
         setColumnFormatting(
             (cid + 1)
         );
         resetHeaderGlyphicons();

         // Alt-glyphs if appropriate (glyphicon in header column should indicate what action will be invoked when clicking it).
         switch(cid) {
             case 0: // id
                 if (rowSortingStates.courseName === 1) {
                     $('#_v_course_name_header').removeClass('glyphicon-arrow-up');
                     $('#_v_course_name_header').addClass('glyphicon-arrow-down');
                 }
                 break;
             case 1: // difficulty
                 if (rowSortingStates.difficulty === 1) {
                     $('#_v_difficulty_header').removeClass('glyphicon-arrow-up');
                     $('#_v_difficulty_header').addClass('glyphicon-arrow-down');
                 }
                 break;
             case 2: // workload
                 if (rowSortingStates.workload === 1) {
                     $('#_v_workload_header').removeClass('glyphicon-arrow-up');
                     $('#_v_workload_header').addClass('glyphicon-arrow-down');
                 }
                 break;
             case 3: // dig
                 if (rowSortingStates.dig === 1) {
                     $('#_v_dig_header').removeClass('glyphicon-arrow-up');
                     $('#_v_dig_header').addClass('glyphicon-arrow-down');
                 }
                 break;
             default: // other
                 console.log('rowSort misfire.');
                 return; // Short-circuit
         }
     };

     Base.getInstance().gHndl.resetAfterAjax = function() {
         rowSortingStates = {courseName: 0,difficulty: 1,workload: 0,dig: 0};
         Base.getInstance().gHndl.rowSort(1); Base.getInstance().gHndl.rowSort(1);
     }

     var populateTable = function() {
         if ((document.getElementById("_gpa_boosters_table_body") as HTMLTableElement).rows.length === 0) {
             var processedRows = [];
             for (var i = 0; i < Base.getInstance().gHndl.rows.length; i++) {
                 processedRows.push(Base.getInstance().gHndl.rows[i].generateRow(i));
             }
             (document.getElementById('_gpa_boosters_table_body') as HTMLTableElement).innerHTML += processedRows.reduce(function(a:any, b:any) {return a + b;}, '');
             Base.getInstance().gHndl.rows.map(function (x:any) {return x.setupEventHandlers();});
         }
     };
     var clearTable = function() { $('#_gpa_boosters_table_body').empty(); };
     populateTable();
 });

////////////////////
// Ready Function //
////////////////////

(function(this: ICourseDiggersGlobalHandle) {

    var g = this;

    // To keep session storage in-sync with changes to selected school.
    // Also publishs existing session storage value, if present.
    new PubSub.PubSubSessionStorageSubscriber(
        'pubsubscription:selected_school', // subscription identifier
        'SCHOOLS_SELECT' // Session Storage key
    );

    var collapseNavbarLinks = function() {
        console.info('collapseNavbarLinks');
        if ($('#links').attr('aria-expanded') == "true") {
            console.info('collapseNavbarLinks did something.');
            $('#js-navbar-links-toggle-btn').click();
        }
    };

    var collapseNavbarSearchForm = function() {
        console.info('collapseNavbarSearchForm');
        console.info($('#js-collapsing-navbar-search-form-toggle').attr('aria-expanded'));
        if ($('#search').attr('aria-expanded') == "true") {
            console.info('collapseNavbarSearchForm did something.');
            $("#js-navbar-search-toggle-btn").click();
        }
    };

    Base.getInstance().cleanupHooks.push(collapseNavbarLinks);
    Base.getInstance().cleanupHooks.push(collapseNavbarSearchForm);


    new PubSub.HtmlInputElementPublisherAndSubscriber(
        'pubsubscription:selected_school', // subscription identifier
        'js-navbar-search-school-select', // HTML id
        () => {
            // On school selection get search space (json) from server
            //   Also if no selection, make text input for search query readonly / disabled; otherwise enable text input box
            // On typing in search input box, give search results

            console.info(`[pubsubscription:selected_school|js-navbar-search-school-select] ${(<HTMLSelectElement>document.getElementById('js-navbar-search-school-select')).value}`)
            if ((<HTMLSelectElement>document.getElementById('js-navbar-search-school-select')).value === '') { // empty school selection
                $('#js-navbar-search-input').prop('disabled', true);
                $("#js-navbar-search-glyph").addClass("grey");
            } else {
                $.getJSON(
                    ('/pages/search_space?school_id=' + (<HTMLSelectElement>document.getElementById('js-navbar-search-school-select')).value),
                    function(json) {
                        (<any>$('#js-navbar-search-input')).autocomplete({
                            appendTo: '#jquery-autocomplete-outermost-div',
                            forceFixPosition: true,
                            lookup: json,
                            lookupFilter: function (suggestion : any, _originalQuery : any, queryLowerCase : string) {
                                return suggestion.key.indexOf(queryLowerCase) !== -1;
                            },
                            lookupLimit: 50,
                            beforeRender: function(container : JQuery) { container.attr("id", "js-navbar-search-suggestions"); },
                            onSelect: function (suggestion : any) {
                                Runtime.visitLink('/pages/show?utf8=%E2%9C%93&digger%5Bcourse_id%5D='  + suggestion.data + '&commit=GO');
                            }
                        });
                        $('#js-navbar-search-input').prop('disabled', false);
                        $("#js-navbar-search-glyph").removeClass("grey");
                    }
                );
            }
        },
        ObjectLifeCycle.ObjectLifeCycle.InfinitePersistence // object life cycle
    );

    $('#search').on('shown.bs.collapse', function () {
        // console.log('#search shown.bs.collapse');
        var jsNavbarSearchInput = <HTMLInputElement>document.getElementById('js-navbar-search-input');
        var jsNavbarSearchSchoolSelect = <HTMLSelectElement>document.getElementById('js-navbar-search-school-select');
        $('#js-navbar-search-suggestions').show();

        if (jsNavbarSearchInput.disabled !== true) {
            jsNavbarSearchInput.focus();
            jsNavbarSearchInput.select();
        } else {
            jsNavbarSearchSchoolSelect.focus();
        }
    });

    $('#search').on('hidden.bs.collapse', function () {
        console.log('#search hidden.bs.collapse');
        $('#js-navbar-search-suggestions').hide();
    });

    Base.getInstance().readyFunc = function() {

        // #####################################
        // BEGIN: COMMON CODE AMONG ALL PAGES ##
        // #####################################


        // ###################################
        // END: COMMON CODE AMONG ALL PAGES ##
        // ###################################


        // ###############################################
        // BEGIN: Code for loading SCHOOLS_SELECT state ##
        // ###############################################


        // ############################################
        // END: Code for pages/home and pages/review ##
        // ############################################


        // ####################################################
        // BEGIN: Code for pages/show for individual courses ##
        // ####################################################

        (Utils.executeIfHtmlElementWithIdExists)('_show_ui_review_table_body', function() {
            $.ajax('/pages/retrieve_course_reviews', {
                type: 'GET',
                dataType: 'script',
                data: {
                    course_id: $('#_show_course_id').val()
                },
                error: function(_jqXHR, textStatus, _errorThrown) {
                    console.error("AJAX Error: " + textStatus);
                },
                success: function(_data, _textStatus, _jqXHR) {
                    console.log("Dynamic review update OK!");
                }
            });
        });

        (Utils.executeIfHtmlElementWithIdExists)('_show_top_tag_tree', function() {
            // Optimization
            (Utils.executeIfHtmlElementWithIdDoesNotExist)('_pages_show_no_reviews', function() {
                $.ajax('/pages/retrieve_top_tags_in_reviews', {
                    type: 'GET',
                    dataType: 'script',
                    data: {
                        course_id: $('#_show_course_id').val()
                    },
                    error: function(_jqXHR, textStatus, _errorThrown) {
                        console.error("AJAX Error: " + textStatus);
                    },
                    success: function(_data, _textStatus, _jqXHR) {
                        console.log("Dynamic top tags update OK!");
                    }
                });
            });

            // To mimic behaviour of AJax call when no Top Tags are found
            (Utils.executeIfHtmlElementWithIdExists)('_pages_show_no_reviews', function() {
                $('#_show_top_tag_tree').append('<p>No tags selected yet.</p>');
            });
        });

        // ##################################################
        // END: Code for pages/show for individual courses ##
        // ##################################################


        // ####################################################
        // BEGIN: Code for REVIEW NEW/CREATE/EDIT form pages ##
        // ####################################################

        setupForm(g);

        // ##################################################
        // END: Code for REVIEW NEW/CREATE/EDIT form pages ##
        // ##################################################


        // ############################################
        // BEGIN: Code for explore GPA Boosters page ##
        // ############################################

        (Utils.executeIfHtmlElementWithIdExists)('_explore_page', function() {
            ExploreTable.setupTable(g);
        });

        // ##########################################
        // END: Code for explore GPA Boosters page ##
        // ##########################################

    };

}).call((window as Window) as ICourseDiggersGlobalHandle);
