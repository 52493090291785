
import { ObjectLifeCycle } from "frontendframework/dist/javascripts/enumerations/object_life_cycle";
import { MiniHtmlViewModel } from "frontendframework/dist/javascripts/mini_html_view_model";

export let buildPieChartDrawingFunction = function(data:any, labels:any, chartHtmlElementId = 'js-chart', chartOuterHtmlElement = 'cp_top_section') {
    let zD : any = labels.map(function(_e:any, i:any) {
        return [labels[i], data[i]];
    });
    zD.unshift(['Category','Percentage']);
    function drawChart() {
        let data : any = google.visualization.arrayToDataTable(zD);

        var options = {
            colors: ['#C71200', '#F0CA00', '#40B500', '#276B00'],
            backgroundColor: { fill:'transparent' },
            chartArea: (((<HTMLElement>document.getElementById(chartOuterHtmlElement)).offsetWidth <= 668) ? {'width': '90%', 'height':'88%','top':'2%'} : {'width': '90%', 'height': '90%'}),
            fontSize: (((<HTMLElement>document.getElementById(chartOuterHtmlElement)).offsetWidth <= 668) ? 14 : 18), // Controls font size for percentages on display
            legend: <any>{position: (((<HTMLElement>document.getElementById(chartOuterHtmlElement)).offsetWidth <= 668) ? 'bottom' : 'right'), textStyle: {fontSize: 15, bold: false}}, // Legend styling
            pieHole: 0.3, // Controls pie hole size
            tooltip: {
                text: 'percentage',
                textStyle: {fontSize: 18} // Controls hover text hover text styling
            }
        };

        var chart = new google.visualization.PieChart(<HTMLElement>document.getElementById(chartHtmlElementId));

        chart.draw(data, options);
    }
    drawChart();
    // gHndl.drawChart = drawChart;
    window.addEventListener("resize", drawChart, false);
};

export const enum GradeDataSchemaVersion { One };

export interface GradeDataMetadataDataSourceInformation {
    id: number;
}

export interface GradeDataMetadata {
    dataSource: GradeDataMetadataDataSourceInformation;
}

// Essentially represents a table of data for a specific course.
// Note that all indices are 0-based to match JavaScript Array indices.
// If the length of the data property is 1, we can safely assume that no
// filtering capability should be present in UI.
// The length of all arrays (of type Array<string|number>) in the data property
// array should be equal to the length of the labels data property array.
// The sum of the lengths of xlabels, filter, and aux data property arrays
// should be equal to the length of the labels data property array.
export interface GradeData {
    v?: GradeDataSchemaVersion;        // GradeDataSchemaVersion
    name: string;                      // Course Name
    labels: Array<string>;             // Represents table headers
    data: Array<Array<string|number>>; // Represents rows of table
    xlabels: Array<number>;            // Indices of the numerical data (for graphing)
    filter?: Array<number>;            // Indices of filter columns.
    aux?: Array<number>;               // Indices of other numerical data that can be compared but not graphed.
    metadata: GradeDataMetadata;
}


export let generateAdvancedStatsBinding = (jsonObject: GradeData, chartHtmlElementId = 'js-chart', chartOuterHtmlElement = 'cp_top_section') => {

    const enum InteractiveStatsViewModelZDCalculationMode { NoFilter, OneFilter, TwoFilter };
    var TERM_FILTER_COLUMN = 0;
    var PROF_FILTER_COLUMN = 1;
    var AVERAGE_GRADE_COLUMN = 2;
    var FAIL_RATE_COLUMN = 3;
    var MAGIC_DEFAULT_VALUE = 'F89rkdsfAFDFafaREWDF';
    var INTERACTIVE_STATS_DEFAULT_EMPTY_DISPLAY_VALUE = 'Not Specified';

    // TODO: Allow custom data aggregation.
    // TODO: Do not rely on hard-coded column order.
    class InteractiveStatsViewModel extends MiniHtmlViewModel.ViewModel {
        public jsonObject: GradeData;
        public termFilter: { [index: string]: Array<number> } = { '': [] };
        public profFilter: { [index: string]: Array<number> } = { '': [] };
        public zD: Array<Array<number|string>>|undefined;
        public averageGrade0: number = 0;
        public averageGrade1: number = 0;
        public averageGrade2: number = 0;
        public failRate0: number = 0;
        public failRate1: number = 0;
        public failRate2: number = 0;
        private doNotSetAverageValues: boolean = false;
        constructor(obj: GradeData, ...bindableProperties: Array<MiniHtmlViewModel.ViewModelProperty<InteractiveStatsViewModel>>) {
            super(ObjectLifeCycle.Transient, ...bindableProperties);
            this.jsonObject = obj;
            $('#js-avg-row').slideDown();
            if (this.jsonObject.filter != null) {
                var filterOptions = this.jsonObject.filter.map(function(_o) { return []; });
                $('#js-cmp-fltr-1a').empty();
                $('#js-cmp-fltr-1b').empty();
                $('#js-cmp-fltr-2a').empty();
                $('#js-cmp-fltr-2b').empty();
                this.termFilter[MAGIC_DEFAULT_VALUE] = [];
                this.profFilter[MAGIC_DEFAULT_VALUE] = [];
                for (var i = 0; i < this.jsonObject.data.length; i++) {
                    var tempTerm = jsonObject.data[i][TERM_FILTER_COLUMN];
                    if (this.termFilter[tempTerm] == null) {
                        this.termFilter[tempTerm] = [i];
                    } else {
                        this.termFilter[tempTerm].push(i);
                    }
                    var tempProf = jsonObject.data[i][PROF_FILTER_COLUMN];
                    if (this.profFilter[tempProf] == null) {
                        this.profFilter[tempProf] = [i];
                    } else {
                        this.profFilter[tempProf].push(i);
                    }
                    this.termFilter[MAGIC_DEFAULT_VALUE].push(i);
                    this.profFilter[MAGIC_DEFAULT_VALUE].push(i);
                    for (var ii = 0; ii < (<Array<number>>jsonObject.filter).length; ii++) {
                        var temp = jsonObject.data[i][(<Array<number>>jsonObject.filter)[ii]];
                        //console.info('filterOptions[' + ii +  ']: ' + filterOptions[ii]);
                        if (!(filterOptions[ii].indexOf(<never>temp) > -1)) {
                            filterOptions[ii].push(<never>temp);
                            var displayVal = temp;
                            if (temp.toString().trim() === '') {
                                displayVal = INTERACTIVE_STATS_DEFAULT_EMPTY_DISPLAY_VALUE;
                            }
                            if (ii === 0) {
                                $('#js-cmp-fltr-1a').append('<option id="js-cmp-fltr-1a-opt-' + this.magicValToMagicKey(temp) + '" value="' + temp + '">' + displayVal + '</option>');
                                $('#js-cmp-fltr-2a').append('<option id="js-cmp-fltr-2a-opt-' + this.magicValToMagicKey(temp) + '" value="' + temp + '">' + displayVal + '</option>');
                            } else {
                                $('#js-cmp-fltr-1b').append('<option id="js-cmp-fltr-1b-opt-' + this.magicValToMagicKey(temp) + '" value="' + temp + '">' + displayVal + '</option>');
                                $('#js-cmp-fltr-2b').append('<option id="js-cmp-fltr-2b-opt-' + this.magicValToMagicKey(temp) + '" value="' + temp + '">' + displayVal + '</option>');
                            }
                        }
                    }
                }
                let opt1a = (<any>$('#js-cmp-fltr-1a option')).sort(function(a : HTMLOptionElement, b : HTMLOptionElement) {
                    if (a.text.toLowerCase() > b.text.toLowerCase()) return -1;
                    else if (a.text.toLowerCase() < b.text.toLowerCase()) return 1;
                    else return 0;
                });
                let opt1b = (<any>$('#js-cmp-fltr-1b option')).sort(function(a : HTMLOptionElement, b : HTMLOptionElement) {
                    if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
                    else if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
                    else return 0;
                });
                let opt2a = (<any>$('#js-cmp-fltr-2a option')).sort(function(a : HTMLOptionElement, b : HTMLOptionElement) {
                    if (a.text.toLowerCase() > b.text.toLowerCase()) return -1;
                    else if (a.text.toLowerCase() < b.text.toLowerCase()) return 1;
                    else return 0;
                });
                let opt2b = (<any>$('#js-cmp-fltr-2b option')).sort(function(a : HTMLOptionElement, b : HTMLOptionElement) {
                    if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
                    else if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
                    else return 0;
                });
                $('#js-cmp-fltr-1a').empty().append('<option id="js-cmp-fltr-1a-opt-' + MAGIC_DEFAULT_VALUE + '" value="' + MAGIC_DEFAULT_VALUE + '">All Terms</option>').append(opt1a);
                $('#js-cmp-fltr-1b').empty().append('<option id="js-cmp-fltr-1b-opt-' + MAGIC_DEFAULT_VALUE + '" value="' + MAGIC_DEFAULT_VALUE + '">All Professors</option>').append(opt1b);
                $('#js-cmp-fltr-2a').empty().append('<option id="js-cmp-fltr-2a-opt-' + MAGIC_DEFAULT_VALUE + '" value="' + MAGIC_DEFAULT_VALUE + '">All Terms</option>').append(opt2a);
                $('#js-cmp-fltr-2b').empty().append('<option id="js-cmp-fltr-2b-opt-' + MAGIC_DEFAULT_VALUE + '" value="' + MAGIC_DEFAULT_VALUE + '">All Professors</option>').append(opt2b);

                this.recalculateZDAndAverages(this, InteractiveStatsViewModelZDCalculationMode.NoFilter);
                this.drawChart(this);
                // Sometimes default values for filter select options do not default to "All" options (does not work in Firefox)
                // Behaviour described above only seen in Firefox and Chrome, never in IE or Safari.
                // This code does no harm since it fixes issue in Chrome (although issue does not always occur (but I always see it in ECON 101))
                $("#js-cmp-fltr-1a option[id='js-cmp-fltr-1a-opt-" + MAGIC_DEFAULT_VALUE + "']").attr("selected", "selected");
                $("#js-cmp-fltr-1b option[id='js-cmp-fltr-1b-opt-" + MAGIC_DEFAULT_VALUE + "']").attr("selected", "selected");
                $("#js-cmp-fltr-2a option[id='js-cmp-fltr-2a-opt-" + MAGIC_DEFAULT_VALUE + "']").attr("selected", "selected");
                $("#js-cmp-fltr-2b option[id='js-cmp-fltr-2b-opt-" + MAGIC_DEFAULT_VALUE + "']").attr("selected", "selected");
            } else {
                // $('#js-avg-row').hide();
                this.teardown();
                $('#js-avg-row-filter-btn').prop('disabled', true);
                // $('#js-avg-row-filter-btn').unbind();
                $('#js-avg-row-filter').unbind();
                $('#js-avg-row-filter').hide();

                this.doNotSetAverageValues = true;
                this.recalculateZDAndAverages(this, InteractiveStatsViewModelZDCalculationMode.NoFilter);
                this.drawChart(this);

                // Temporary fix for new SFU data display scheme (hack)
                if (obj.aux != null) {
                    if (obj.aux.length !== 2) console.error('Unanticipated situation. ERROR 443k90afjio0r5u2');

                    let medianAuxCol = 0;
                    let failRateAuxCol = 1;

                    // console.log("Setting AVG ROW items: LOCATION 324dwf2");
                    $('#js-avg-grade-0-label').html(obj.labels[medianAuxCol]);
                    $('#js-fail-rate-0-label').html(obj.labels[failRateAuxCol]);

                    $('#js-avg-grade-0').html(<string>obj.data[0][medianAuxCol]);
                    $('#js-fail-rate-0').html((<number>obj.data[0][failRateAuxCol]).toFixed(1).toString() + '%');
                }
            }
        }

        recalculateZDAndAverages(obj: InteractiveStatsViewModel, mode: InteractiveStatsViewModelZDCalculationMode) {
            switch (mode) {
                case InteractiveStatsViewModelZDCalculationMode.NoFilter:
                    console.info('No Filter');
                    var numberOfStudents = 0;
                    var failRateProductSum = 0;
                    var averageGradeProductSum = 0;
                    var data : any[] = []; data.length = obj.jsonObject.xlabels.length;
                    for (var e = 0; e < data.length; e++) { data[e] = 0; }
                    var labels : any[] = []; labels.length = obj.jsonObject.xlabels.length;
                    for (var k = 0; k < obj.jsonObject.xlabels.length; k++) {
                        var x = obj.jsonObject.xlabels[k];
                        labels[k] = obj.jsonObject.labels[x];
                        for (var m = 0; m < obj.jsonObject.data.length; m++) {
                            data[k] = data[k] + obj.jsonObject.data[m][x];
                        }
                    }
                    for (var aa = 0; aa < obj.jsonObject.data.length; aa++) {
                        var numberOfStudentsInClass = 0;
                        for (var bb = 0; bb < obj.jsonObject.xlabels.length; bb++) {
                            numberOfStudentsInClass = numberOfStudentsInClass + <number>obj.jsonObject.data[aa][obj.jsonObject.xlabels[bb]];
                        }
                        numberOfStudents = numberOfStudents + numberOfStudentsInClass;
                        failRateProductSum = failRateProductSum + (numberOfStudentsInClass * <number>obj.jsonObject.data[aa][FAIL_RATE_COLUMN]);
                        averageGradeProductSum = averageGradeProductSum + (numberOfStudentsInClass * <number>obj.jsonObject.data[aa][AVERAGE_GRADE_COLUMN]);
                    }
                    var totalDataSum = data.reduce(function(a, b) { return a + b; });
                    for (var kk = 0; kk < data.length; kk++) {
                        data[kk] = +(100 * data[kk] / totalDataSum).toFixed(2);
                    }
                    //console.info("Data: " + data);
                    //console.info("Labels: " + labels);
                    obj.zD = labels.map(function(_e, i) {
                        return [labels[i], data[i]];
                    });
                    //console.info("zD before unshift: " + obj.zD);
                    obj.zD.unshift(['Category','Percentage']);
                    //console.info(obj.zD);
                    //console.info('# of students: ' + numberOfStudents);
                    obj.averageGrade0 = averageGradeProductSum / numberOfStudents;
                    //console.info("Average Grade 1: " + obj.averageGrade1);
                    obj.failRate0 = failRateProductSum / numberOfStudents;
                    //console.info("Fail Rate 1: " + obj.failRate1);
                    break;
                case InteractiveStatsViewModelZDCalculationMode.OneFilter:
                    console.info("OneFilter");
                    var oneFilterFlags = obj.oneFilterOptions();
                    var profFilterRowIndices = obj.profFilter[oneFilterFlags.prof];
                    //console.info(profFilterRowIndices);
                    var termFilterRowIndices = obj.termFilter[oneFilterFlags.term];
                    //console.info(termFilterRowIndices);
                    var validRowIndices = profFilterRowIndices.filter(function(n) {
                        return termFilterRowIndices.indexOf(n) != -1;
                    });
                    var numberOfStudents = 0;
                    var failRateProductSum = 0;
                    var averageGradeProductSum = 0;

                    for (var aa = 0; aa < validRowIndices.length; aa++) {
                        var row = obj.jsonObject.data[validRowIndices[aa]];
                        var numberOfStudentsInClass = 0;
                        for (var bb = 0; bb < obj.jsonObject.xlabels.length; bb++) {
                            numberOfStudentsInClass = numberOfStudentsInClass + <number>row[obj.jsonObject.xlabels[bb]];
                        }
                        numberOfStudents = numberOfStudents + numberOfStudentsInClass;
                        failRateProductSum = failRateProductSum + (numberOfStudentsInClass * <number>row[FAIL_RATE_COLUMN]);
                        averageGradeProductSum = averageGradeProductSum + (numberOfStudentsInClass * <number>row[AVERAGE_GRADE_COLUMN]);
                    }

                    var data : any[] = []; data.length = obj.jsonObject.xlabels.length;
                    for (var e = 0; e < data.length; e++) { data[e] = 0; }
                    var labels : any[] = []; labels.length = obj.jsonObject.xlabels.length;
                    for (var k = 0; k < obj.jsonObject.xlabels.length; k++) {
                        var x = obj.jsonObject.xlabels[k];
                        labels[k] = obj.jsonObject.labels[x];
                        for (var m = 0; m < validRowIndices.length; m++) {
                            data[k] = data[k] + obj.jsonObject.data[validRowIndices[m]][x];
                        }
                    }
                    var totalDataSum = data.reduce(function(a, b) { return a + b; });
                    for (var kk = 0; kk < data.length; kk++) {
                        data[kk] = +(100 * data[kk] / totalDataSum).toFixed(2);
                    }
                    //console.info("Data: " + data);
                    //console.info("Labels: " + labels);
                    obj.zD = labels.map(function(_e, i) {
                        return [labels[i], data[i]];
                    });
                    //console.info("zD before unshift: " + obj.zD);
                    obj.zD.unshift(['Category','Percentage']);
                    //console.info(obj.zD);
                    //console.info('# of students: ' + numberOfStudents);
                    obj.averageGrade1 = averageGradeProductSum / numberOfStudents;
                    //console.info("Average Grade 1: " + obj.averageGrade1);
                    obj.failRate1 = failRateProductSum / numberOfStudents;
                    //console.info("Fail Rate 1: " + obj.failRate1);
                    break;
                case InteractiveStatsViewModelZDCalculationMode.TwoFilter:
                    console.info('Two Filter');
                    var twoFilterFlags = obj.twoFilterOptions();
                    var prof1FilterRowIndices = obj.profFilter[twoFilterFlags.filter1.prof];
                    var term1FilterRowIndices = obj.termFilter[twoFilterFlags.filter1.term];
                    var prof2FilterRowIndices = obj.profFilter[twoFilterFlags.filter2.prof];
                    var term2FilterRowIndices = obj.termFilter[twoFilterFlags.filter2.term];
                    var validRowIndices1 = prof1FilterRowIndices.filter(function(n) {
                        return term1FilterRowIndices.indexOf(n) != -1;
                    });
                    var validRowIndices2 = prof2FilterRowIndices.filter(function(n) {
                        return term2FilterRowIndices.indexOf(n) != -1;
                    });

                    var numberOfStudents1 = 0;
                    var failRateProductSum1 = 0;
                    var averageGradeProductSum1 = 0;
                    var numberOfStudents2 = 0;
                    var failRateProductSum2 = 0;
                    var averageGradeProductSum2 = 0;

                    for (var aa1 = 0; aa1 < validRowIndices1.length; aa1++) {
                        var row = obj.jsonObject.data[validRowIndices1[aa1]];
                        var numberOfStudentsInClass1 = 0;
                        for (var bb = 0; bb < obj.jsonObject.xlabels.length; bb++) {
                            numberOfStudentsInClass1 = numberOfStudentsInClass1 + <number>row[obj.jsonObject.xlabels[bb]];
                        }
                        numberOfStudents1 = numberOfStudents1 + numberOfStudentsInClass1;
                        failRateProductSum1 = failRateProductSum1 + (numberOfStudentsInClass1 * <number>row[FAIL_RATE_COLUMN]);
                        averageGradeProductSum1 = averageGradeProductSum1 + (numberOfStudentsInClass1 * <number>row[AVERAGE_GRADE_COLUMN]);
                    }

                    for (var aa2 = 0; aa2 < validRowIndices2.length; aa2++) {
                        var row = obj.jsonObject.data[validRowIndices2[aa2]];
                        var numberOfStudentsInClass2 = 0;
                        for (var bb = 0; bb < obj.jsonObject.xlabels.length; bb++) {
                            numberOfStudentsInClass2 = numberOfStudentsInClass2 + <number>row[obj.jsonObject.xlabels[bb]];
                        }
                        numberOfStudents2 = numberOfStudents2 + numberOfStudentsInClass2;
                        failRateProductSum2 = failRateProductSum2 + (numberOfStudentsInClass2 * <number>row[FAIL_RATE_COLUMN]);
                        averageGradeProductSum2 = averageGradeProductSum2 + (numberOfStudentsInClass2 * <number>row[AVERAGE_GRADE_COLUMN]);
                    }

                    var data1 : any[] = []; data1.length = obj.jsonObject.xlabels.length;
                    for (var e1 = 0; e1 < data1.length; e1++) { data1[e1] = 0; }
                    var labels : any[] = []; labels.length = obj.jsonObject.xlabels.length;
                    for (var k1 = 0; k1 < obj.jsonObject.xlabels.length; k1++) {
                        var x1 = obj.jsonObject.xlabels[k1];
                        labels[k1] = obj.jsonObject.labels[x1];
                        for (var m1 = 0; m1 < validRowIndices1.length; m1++) {
                            data1[k1] = data1[k1] + obj.jsonObject.data[validRowIndices1[m1]][x1];
                        }
                    }
                    var totalData1Sum = data1.reduce(function(a, b) { return a + b; });
                    for (var kk1 = 0; kk1 < data1.length; kk1++) {
                        data1[kk1] = +(100 * data1[kk1] / totalData1Sum).toFixed(2);
                    }
                    //console.info("Data1: " + data1);

                    var data2 : any[] = []; data2.length = obj.jsonObject.xlabels.length;
                    for (var e2 = 0; e2 < data2.length; e2++) { data2[e2] = 0; }
                    for (var k2 = 0; k2 < obj.jsonObject.xlabels.length; k2++) {
                        var x2 = obj.jsonObject.xlabels[k2];
                        for (var m2 = 0; m2 < validRowIndices2.length; m2++) {
                            data2[k2] = data2[k2] + obj.jsonObject.data[validRowIndices2[m2]][x2];
                        }
                    }
                    var totalData2Sum = data2.reduce(function(a, b) { return a + b; });
                    for (var kk2 = 0; kk2 < data2.length; kk2++) {
                        data2[kk2] = +(100 * data2[kk2] / totalData2Sum).toFixed(2);
                    }
                    //console.info("Data2: " + data2);

                    //console.info("Labels: " + labels);
                    obj.zD = labels.map(function(_e, i) {
                        return [labels[i], data1[i], data2[i]];
                    });
                    //console.info("zD before unshift: " + obj.zD);
                    obj.zD.unshift(['Category','Percentage1','Percentage2']);
                    //console.info(obj.zD);

                    //console.info('# of students1: ' + numberOfStudents1);
                    obj.averageGrade1 = averageGradeProductSum1 / numberOfStudents1;
                    //console.info("Average Grade 1: " + obj.averageGrade1);
                    obj.failRate1 = failRateProductSum1 / numberOfStudents1;
                    //console.info("Fail Rate 1: " + obj.failRate1);

                    //console.info('# of students2: ' + numberOfStudents2);
                    obj.averageGrade2 = averageGradeProductSum2 / numberOfStudents2;
                    //console.info("Average Grade 2: " + obj.averageGrade2);
                    obj.failRate2 = failRateProductSum2 / numberOfStudents2;
                    //console.info("Fail Rate 2: " + obj.failRate2);
                    break;
                default:
                    console.error('Given InteractiveStatsViewModelZDCalculationMode (' + mode + ') not understood by program.');
                    break;
            }
        }

        drawChart(obj: InteractiveStatsViewModel) {
            var zD: Array<Array<number|string>>|undefined = obj.zD;
            //console.log('Boom');
            var data = google.visualization.arrayToDataTable(zD as any[]);

            var options = {
                backgroundColor: { fill:'transparent' },
                colors: ['#2F3943', 'orange'], // First element in array controls the controls the colors of the first data series
                //chartArea: ((document.getElementById(chartOuterHtmlElement).offsetWidth <= 668) ? {'width': '50%', 'top':'2%'} : {'width' : '75%', 'left' : '25', 'top': '20'}),
                chartArea: (((<HTMLElement>document.getElementById(chartOuterHtmlElement)).offsetWidth <= 668) ? {'width': '100%', 'left':'10', 'top':'2%', 'height':'75%'} : {'width' : '100%', 'left' : '25', 'top': '20'}),
                fontSize: (((<HTMLElement>document.getElementById(chartOuterHtmlElement)).offsetWidth <= 668) ? 14 : 18), // Controls font size for percentages on display
                legend: {position: 'none'}, // Legend styling
                tooltip: {
                    text: 'percentage',
                    textStyle: {fontSize: 18} // Controls hover text hover text styling
                }
            };

            var chart = new google.visualization.ColumnChart(<HTMLElement>document.getElementById(chartHtmlElementId));

            chart.draw(data, options as any);
            if (!this.doNotSetAverageValues) {
                // console.log('watermelon');
                obj.setAverageValues();
            }
        }

        setAverageValues() {
            // console.log("Setting AVG ROW items: LOCATION of0-2r@#RR");
            if (isNaN(this.averageGrade0)) {
                $('#js-avg-grade-0').html('N/A');
            } else {
                $('#js-avg-grade-0').html(this.averageGrade0.toFixed(0) + '%');
            }

            if (isNaN(this.failRate0)) {
                $('#js-fail-rate-0').html('N/A');
            } else {
                $('#js-fail-rate-0').html(this.failRate0.toFixed(0) + '%');
            }

            if (isNaN(this.averageGrade1)) {
                $('#js-avg-grade-1').html('N/A');
            } else {
                $('#js-avg-grade-1').html(this.averageGrade1.toFixed(0) + '%');
            }

            if (isNaN(this.failRate1)) {
                $('#js-fail-rate-1').html('N/A');
            } else {
                $('#js-fail-rate-1').html(this.failRate1.toFixed(0) + '%');
            }

            if (isNaN(this.averageGrade2)) {
                $('#js-avg-grade-2').html('N/A');
            } else {
                $('#js-avg-grade-2').html(this.averageGrade2.toFixed(0) + '%');
            }

            if (isNaN(this.failRate2)) {
                $('#js-fail-rate-2').html('N/A');
            } else {
                $('#js-fail-rate-2').html(this.failRate2.toFixed(0) + '%');
            }
        }

        oneFilterOptions() {
            var selectedTerm = this.idToBindableProperty['js-cmp-fltr-1a'].value;
            var selectedProf = this.idToBindableProperty['js-cmp-fltr-1b'].value;
            //console.info({ prof: selectedProf, term: selectedTerm });
            return { prof: selectedProf, term: selectedTerm };
        }

        twoFilterOptions() {
            var selectedTerm1 = this.idToBindableProperty['js-cmp-fltr-1a'].value;
            var selectedProf1 = this.idToBindableProperty['js-cmp-fltr-1b'].value;
            var selectedTerm2 = this.idToBindableProperty['js-cmp-fltr-2a'].value;
            var selectedProf2 = this.idToBindableProperty['js-cmp-fltr-2b'].value;
            //console.info({
            //    filter1: { prof: selectedProf1, term: selectedTerm1 },
            //    filter2: { prof: selectedProf2, term: selectedTerm2 }
            //});
            return {
                filter1: { prof: selectedProf1, term: selectedTerm1 },
                filter2: { prof: selectedProf2, term: selectedTerm2 }
            };
        }

        toggleFilter() {
            // console.log('toggleFilter fired (connected to #js-row-filter-btn)');
            var filterCheckboxVal = this.idToBindableProperty['js-row-filter-btn'];

            if (filterCheckboxVal.value) { // Show js-filter-outer-wrapper
                $('#js-aggregate-scores').slideUp();
                $('.hide-on-filter-btn-press').fadeOut();
                $('#js-filter-outer-wrapper').slideDown();
                $('#js-avg-row-filter').addClass('js-avg-row-filter-hover-state');

                if (this.idToBindableProperty['js-compare-to-checkbox'].value) {
                    this.recalculateZDAndAverages(this, InteractiveStatsViewModelZDCalculationMode.TwoFilter);
                } else {
                    this.recalculateZDAndAverages(this, InteractiveStatsViewModelZDCalculationMode.OneFilter);
                }
            } else {
                $('#js-filter-outer-wrapper').slideUp();
                $('.hide-on-filter-btn-press').fadeIn();
                $('#js-aggregate-scores').slideDown();
                $('#js-avg-row-filter').removeClass('js-avg-row-filter-hover-state');
                this.recalculateZDAndAverages(this, InteractiveStatsViewModelZDCalculationMode.NoFilter);
            }
            this.drawChart(this);
        }

        compareToCheckboxToggle() {
            var compareToCheckboxVal = this.idToBindableProperty['js-compare-to-checkbox'];

            if (compareToCheckboxVal.value) { // Ungrey things and undisable them
                $('#js-avg-row-div-comp-2a').fadeIn().css("display","inline-block");
                $('#js-avg-row-div-comp-2b').fadeIn().css("display","inline-block");
                $('#js-cmp-fltr-2a').removeClass('btn-filter-no-select');
                $('#js-cmp-fltr-2b').removeClass('btn-filter-no-select');
                $('#js-cmp-fltr-2a').removeAttr('disabled');
                $('#js-cmp-fltr-2b').removeAttr('disabled');
                this.recalculateZDAndAverages(this, InteractiveStatsViewModelZDCalculationMode.TwoFilter);
            } else {
                $('#js-avg-row-div-comp-2a').fadeOut();
                $('#js-avg-row-div-comp-2b').fadeOut();
                $('#js-cmp-fltr-2a').addClass('btn-filter-no-select');
                $('#js-cmp-fltr-2b').addClass('btn-filter-no-select');
                $('#js-cmp-fltr-2a').prop('disabled', true);
                $('#js-cmp-fltr-2b').prop('disabled', true);
                this.recalculateZDAndAverages(this, InteractiveStatsViewModelZDCalculationMode.OneFilter);
            }
            this.drawChart(this);
        }

        magicValToMagicKey(val : number|string) {
            // Returns string with all non-alphanumeric characters replaced with nothing
            return val.toString().replace(/[^a-z0-9]/gi,'');
        }

        uniqueElementsInArray(arr : any[]) {
            return arr.reduce(function(p, c) {
                if (p.indexOf(c) < 0) p.push(c);
                return p;
            }, []);
        }

        getAllowableTerms(profKey : string) {
            var toReturn : any[] = [];
            var thiz = this;
            this.profFilter[profKey].forEach(function(rowIndex) {
                var row = thiz.jsonObject.data[rowIndex];
                if (profKey.toString().trim() !== MAGIC_DEFAULT_VALUE) {
                    if (row[PROF_FILTER_COLUMN] === profKey) toReturn.push(row[TERM_FILTER_COLUMN]);
                } else {
                    toReturn.push(row[TERM_FILTER_COLUMN]);
                }
            });

            //console.log('Found ' + toReturn.length);

            return this.uniqueElementsInArray(toReturn);
        }

        getAllowableProfs(termKey : string) {
            var toReturn : any[] = [];
            var thiz = this;
            this.termFilter[termKey].forEach(function(rowIndex) {
                var row = thiz.jsonObject.data[rowIndex];
                if (termKey.toString().trim() !== MAGIC_DEFAULT_VALUE) {
                    if (row[TERM_FILTER_COLUMN] === termKey) toReturn.push(row[PROF_FILTER_COLUMN]);
                } else {
                    toReturn.push(row[PROF_FILTER_COLUMN]);
                }
            });

            //console.log('Found ' + toReturn.length);

            return this.uniqueElementsInArray(toReturn);
        }

        cmpFilter1BDisable() {
            console.log('cmpFilter1BDisable()');
            var thiz = this;
            //$('#js-cmp-fltr-1a').children().each(function(index, optionItem) {
            //    (<HTMLElement>optionItem).style.display = '';
            //    (<any>optionItem).disabled = '';
            //});
            $('#js-cmp-fltr-1b').children().each(function(_index, optionItem) {
                (<HTMLElement>optionItem).style.display = 'none';
                (<any>optionItem).disabled = 'disabled';
            });
            var selectedTerm1 = this.idToBindableProperty['js-cmp-fltr-1a'].value;
            this.getAllowableProfs(selectedTerm1).forEach(function(elem : any) {
                //console.log('YESSSS');
                elem = thiz.magicValToMagicKey(elem);
                //console.info('#js-cmp-fltr-1b-opt-' + elem);
                $('#js-cmp-fltr-1b-opt-' + elem).show().removeAttr('disabled');
            });
            $('#js-cmp-fltr-1b-opt-' + MAGIC_DEFAULT_VALUE).show().removeAttr('disabled');
        }

        cmpFilter1ADisable() {
            console.log('cmpFilter1ADisable()');
            var thiz = this;
            //$('#js-cmp-fltr-1b').children().each(function(index, optionItem) {
            //    (<HTMLElement>optionItem).style.display = '';
            //    (<any>optionItem).disabled = '';
            //});
            $('#js-cmp-fltr-1a').children().each(function(_index, optionItem) {
                (<HTMLElement>optionItem).style.display = 'none';
                (<any>optionItem).disabled = 'disabled';
            });
            var selectedProf1 = this.idToBindableProperty['js-cmp-fltr-1b'].value;
            this.getAllowableTerms(selectedProf1).forEach(function(elem : any) {
                //console.log('YESSSS');
                elem = thiz.magicValToMagicKey(elem);
                //console.info('#js-cmp-fltr-1a-opt-' + elem);
                $('#js-cmp-fltr-1a-opt-' + elem).show().removeAttr('disabled');
            });
            $('#js-cmp-fltr-1a-opt-' + MAGIC_DEFAULT_VALUE).show().removeAttr('disabled');
        }

        cmpFilter2BDisable() {
            console.log('cmpFilter2BDisable()');
            var thiz = this;
            //$('#js-cmp-fltr-2a').children().each(function(index, optionItem) {
            //    (<HTMLElement>optionItem).style.display = '';
            //    (<any>optionItem).disabled = '';
            //});
            $('#js-cmp-fltr-2b').children().each(function(_index, optionItem) {
                (<HTMLElement>optionItem).style.display = 'none';
                (<any>optionItem).disabled = 'disabled';
            });
            var selectedTerm2 = this.idToBindableProperty['js-cmp-fltr-2a'].value;
            this.getAllowableProfs(selectedTerm2).forEach(function(elem : any) {
                //console.log('YESSSS');
                elem = thiz.magicValToMagicKey(elem);
                //console.info('#js-cmp-fltr-2b-opt-' + elem);
                $('#js-cmp-fltr-2b-opt-' + elem).show().removeAttr('disabled');
            });
            $('#js-cmp-fltr-2b-opt-' + MAGIC_DEFAULT_VALUE).show().removeAttr('disabled');
        }

        cmpFilter2ADisable() {
            console.log('cmpFilter2ADisable()');
            var thiz = this;
            //$('#js-cmp-fltr-2b').children().each(function(index, optionItem) {
            //    (<HTMLElement>optionItem).style.display = '';
            //    (<any>optionItem).disabled = '';
            //});
            $('#js-cmp-fltr-2a').children().each(function(_index, optionItem) {
                (<HTMLElement>optionItem).style.display = 'none';
                (<any>optionItem).disabled = 'disabled';
            });
            var selectedProf2 = this.idToBindableProperty['js-cmp-fltr-2b'].value;
            this.getAllowableTerms(selectedProf2).forEach(function(elem : any) {
                //console.log('YESSSS');
                elem = thiz.magicValToMagicKey(elem);
                //console.info('#js-cmp-fltr-2a-opt-' + elem);
                $('#js-cmp-fltr-2a-opt-' + elem).show().removeAttr('disabled');
            });
            $('#js-cmp-fltr-2a-opt-' + MAGIC_DEFAULT_VALUE).show().removeAttr('disabled');
        }

        cmpFilter1aChange() {
            // console.log('cmpFilter1aChange');
            if (this.idToBindableProperty['js-compare-to-checkbox'].value) {
                this.recalculateZDAndAverages(this, InteractiveStatsViewModelZDCalculationMode.TwoFilter);
            } else {
                this.recalculateZDAndAverages(this, InteractiveStatsViewModelZDCalculationMode.OneFilter);
            }
            this.cmpFilter1BDisable();
            this.drawChart(this);
        }

        cmpFilter1bChange() {
            // console.log('cmpFilter1bChange');
            if (this.idToBindableProperty['js-compare-to-checkbox'].value) {
                this.recalculateZDAndAverages(this, InteractiveStatsViewModelZDCalculationMode.TwoFilter);
            } else {
                this.recalculateZDAndAverages(this, InteractiveStatsViewModelZDCalculationMode.OneFilter);
            }
            this.cmpFilter1ADisable();
            this.drawChart(this);
        }

        cmpFilter2aChange() {
            // console.log('cmpFilter2aChange');
            this.recalculateZDAndAverages(this, InteractiveStatsViewModelZDCalculationMode.TwoFilter);
            this.cmpFilter2BDisable();
            this.drawChart(this);
        }

        cmpFilter2bChange() {
            // console.log('cmpFilter2bChange');
            this.recalculateZDAndAverages(this, InteractiveStatsViewModelZDCalculationMode.TwoFilter);
            this.cmpFilter2ADisable();
            this.drawChart(this);
        }
    }

    // TODO: Add code for JSON Grade Data Loader
    var interactiveForm = new InteractiveStatsViewModel(jsonObject,
                                                        new MiniHtmlViewModel.ViewModelProperty<InteractiveStatsViewModel>(
                                                            MiniHtmlViewModel.BindingMode.OneWayRead, 'js-row-filter-btn',
                                                            false, function(val: any) { // setDataFunc
                                                                (<HTMLInputElement>document.getElementById('js-row-filter-btn')).checked = val;
                                                            }, function() { // getDataFunc
                                                                return (<HTMLInputElement>document.getElementById('js-row-filter-btn')).checked;
                                                            }, function (vm: InteractiveStatsViewModel) { // onChangeFunc
                                                                vm.toggleFilter();
                                                                //console.log('ViewModel event firing #js-row-filter-btn');
                                                            }
                                                        ),
                                                        new MiniHtmlViewModel.ViewModelProperty<InteractiveStatsViewModel>(
                                                            MiniHtmlViewModel.BindingMode.OneWayRead, 'js-compare-to-checkbox',
                                                            false, function(val: any) { // setDataFunc
                                                                (<HTMLInputElement>document.getElementById('js-compare-to-checkbox')).checked = val;
                                                            }, function() { // getDataFunc
                                                                return (<HTMLInputElement>document.getElementById('js-compare-to-checkbox')).checked;
                                                            }, function (vm: InteractiveStatsViewModel) { // onChangeFunc
                                                                vm.compareToCheckboxToggle();
                                                                //console.log('ViewModel event firing #js-compare-to-checkbox');
                                                            }
                                                        ),
                                                        new MiniHtmlViewModel.ViewModelProperty<InteractiveStatsViewModel>(
                                                            MiniHtmlViewModel.BindingMode.OneWayRead, 'js-cmp-fltr-1a', MAGIC_DEFAULT_VALUE,
                                                            undefined, undefined, function(vm: InteractiveStatsViewModel) { vm.cmpFilter1aChange(); }
                                                        ),
                                                        new MiniHtmlViewModel.ViewModelProperty<InteractiveStatsViewModel>(
                                                            MiniHtmlViewModel.BindingMode.OneWayRead, 'js-cmp-fltr-1b', MAGIC_DEFAULT_VALUE,
                                                            undefined, undefined, function(vm: InteractiveStatsViewModel) { vm.cmpFilter1bChange(); }
                                                        ),
                                                        new MiniHtmlViewModel.ViewModelProperty<InteractiveStatsViewModel>(
                                                            MiniHtmlViewModel.BindingMode.OneWayRead, 'js-cmp-fltr-2a', MAGIC_DEFAULT_VALUE,
                                                            undefined, undefined, function(vm: InteractiveStatsViewModel) { vm.cmpFilter2aChange(); }
                                                        ),
                                                        new MiniHtmlViewModel.ViewModelProperty<InteractiveStatsViewModel>(
                                                            MiniHtmlViewModel.BindingMode.OneWayRead, 'js-cmp-fltr-2b', MAGIC_DEFAULT_VALUE,
                                                            undefined, undefined, function(vm: InteractiveStatsViewModel) { vm.cmpFilter2bChange(); }
                                                        )
                                                       );

    (<HTMLElement>document.getElementById(chartHtmlElementId)).addEventListener("resize", function() { interactiveForm.drawChart(interactiveForm); }, false);
}
