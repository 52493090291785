
import * as PubSub from "frontendframework/dist/javascripts/pub_sub";
import ICourseDiggersGlobalHandle from "../common/i_course_diggers_global_handle";

export interface IExploreTableRow {
    courseId: number; // Integer
    courseName: string
    difficulty: number; // Float
    workload: number; // Float
    dig: number; // Float
    events_setup_p: boolean;
    generateRow: ((x:number) => string);
    setupEventHandlers: (() => void);
}

export let setupTable = (globalContext: ICourseDiggersGlobalHandle) => {
    let g = globalContext;
    new PubSub.HtmlInputElementPublisherAndSubscriber(
        'pubsubscription:selected_school', // subscription identifier
        'schools_explore_select2', // HTML id
        () => {
            if ($("#schools_explore_select2 option:selected").val() !== "") {
                // TODO: Try to incorporate this logic into explore page code
                $('#js-explore-table-wrapper').slideDown();

                $.ajax('/pages/explore_school_course_reviews2', {
                    type: 'GET',
                    dataType: 'script',
                    data: {
                        school_id: $("#schools_explore_select2 option:selected").val()
                    },
                    error: function(_jqXHR, textStatus, _errorThrown) {
                        console.error("AJAX Error: " + textStatus);
                    },
                    success: function(_data, _textStatus, _jqXHR) {
                        console.log("Dynamic school explore select OK!");
                    }
                });
            } else {
                // TODO: Try to incorporate this logic into explore page code
                $('#js-explore-table-wrapper').slideUp();
                g.rows = []; (<(() => void)>g.resetAfterAjax)();
            }
        }
    );
}
