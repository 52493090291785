
import {IUserSession} from "../models/user_session";
import {setupRouting} from "./routing";
import {setupServices} from "./services";

export default class Application {
    public static getInstance() {
        if (!Application.instance) {
            this.instance = new Application();
        }
        return this.instance;
    }
    private static instance: Application;
    public userSession?: IUserSession;
    private constructor() {
        console.info("Application initialized");
        setupRouting();
        setupServices();
    }
}
