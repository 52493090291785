
let barChart = (selector: string, data: any) => {
    let resize = async () => {
        const d3 = await import(/* webpackChunkName: "d3" */ "d3");
        $(selector).empty();
        var svg = d3.select(selector),
        margin = {top: 20, right: 20, bottom: 30, left: 40},
        width = ($(selector).width() as number) - margin.left - margin.right,
        height = ($(selector).height() as number) - margin.top - margin.bottom - 80;
        //console.info();

        var x = (<any>d3).scaleBand().rangeRound([0, width]).padding(0.1),
        y = (<any>d3).scaleLinear().rangeRound([height, 0]);

        var g = svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        let yMax = <number>d3.max(data, function(d: any[]) { return <number>d[2]; });
        x.domain(data.map(function(d: any[]) { return d[1]; }));
        y.domain([0, yMax]).nice();

        g.append("g")
            .attr("class", "axis axis--x")
            .attr("transform", "translate(0," + height + ")")
            .call((<any>d3).axisBottom(x))
            .selectAll('text').attr("y", 0)
            .attr("x", 9)
            .attr("dy", ".35em")
            .attr("transform", "rotate(90)")
            .style("text-anchor", "start");

        g.append("g")
            .attr("class", "axis axis--y")
            .call((<any>d3).axisLeft(y).ticks(yMax / 10))
            .append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 6)
            .attr("dy", "0.71em")
            .attr("text-anchor", "end")
            .text("Number of Reviews");

        let wrapper = g.selectAll(".bar");
        wrapper.data(data)
            .enter().append("rect")
            .attr("class", "bar")
            .attr("x", <any>function(d: any[]) { return x(d[1]); })
            .attr("y", <any>function(d: any[]) { return y(d[2]); })
            .attr("width", x.bandwidth())
            .attr("height", <any>function(d: any[]) {
                // console.info(y(d[2]));
                return height - y(d[2]); });
        wrapper.data(data).enter()
            .append("text")
            .attr("class", "bar")
            .attr("text-anchor", "middle")
            .attr("x", <any>function(d: any) { return x(d[1]) + x.bandwidth()/2; })
            .attr("y", <any>function(d: any) { return y(d[2]) - 5; })
            .text(<any>function(d:any) { console.log(d); return d[2]; });
    }
    $(window).on('resize', resize);
    resize();
}
export default barChart;
