
import Base from "frontendframework/dist/javascripts/base";
import ICourseDiggersGlobalHandle from "../common/i_course_diggers_global_handle";
import * as Utils from "../common/utils";
import $ from "jquery";

const setupForm = (globalContext:ICourseDiggersGlobalHandle) => {
    let g = globalContext;
    g.review_form_prefix = (document.getElementById('review_professor_opinion_category') == null) ? 'missing_x_review' : 'review';

    g.review_submission_ui_tag_select = function(partial_tag_id : string, ui_id : string) {
        var tag_id_checked_prop = $('#' + g.review_form_prefix + partial_tag_id).prop("checked");
        if (tag_id_checked_prop) {
            $(ui_id).removeClass("selected-tag");
            $('#' + g.review_form_prefix + partial_tag_id).prop("checked", false);
            Base.getInstance().gHndl.__TAG_COUNT = Base.getInstance().gHndl.__TAG_COUNT - 1;
        } else {
            Base.getInstance().gHndl.__TAG_COUNT = Base.getInstance().gHndl.__TAG_COUNT + 1;
            if (!(Base.getInstance().gHndl.__TAG_COUNT > 3)) {
                $(ui_id).addClass("selected-tag");
                $('#' + g.review_form_prefix + partial_tag_id).prop("checked", true);
            } else {
                Base.getInstance().gHndl.__TAG_COUNT = Base.getInstance().gHndl.__TAG_COUNT - 1;
                alert('Can only select 3 tags. Unselect a selected tag to select this tag');
            }
        }
    };

    g.review_submission_ui_thumbs_down = function() {
        $('#_review_form_ui_thumbs_up').removeClass('thumbs-up');
        $('#_review_form_ui_thumbs_up').addClass('thumbs-u-grey');
        $('#_review_form_ui_thumbs_down').removeClass('thumbs-d-grey');
        $('#_review_form_ui_thumbs_down').addClass('thumbs-down');
        $('#' + g.review_form_prefix + '_thumbs_rating').val(0);
    };

    g.review_submission_ui_thumbs_up = function() {
        $('#_review_form_ui_thumbs_up').removeClass('thumbs-u-grey');
        $('#_review_form_ui_thumbs_up').addClass('thumbs-up');
        $('#_review_form_ui_thumbs_down').removeClass('thumbs-down');
        $('#_review_form_ui_thumbs_down').addClass('thumbs-d-grey');
        $('#' + g.review_form_prefix + '_thumbs_rating').val(1);
    };

    (<((id : string, f : (() => any)) => void)>Utils.executeIfHtmlElementWithIdExists)('_review_form_ui_smiley', function() {
        g.can_smiley_be_clicked = false;
        var set_prof_opin_smiley = function() {
            $('#_review_form_ui_smiley').removeClass('smile_grey');
            $('#_review_form_ui_smiley').addClass('smile_yellow');
            $('#' + g.review_form_prefix + '_professor_opinion_category').val(1);
        };
        var unset_prof_opin_smiley = function() {
            $('#_review_form_ui_smiley').removeClass('smile_yellow');
            $('#_review_form_ui_smiley').addClass('smile_grey');
            $('#' + g.review_form_prefix + '_professor_opinion_category').val(0);
        };
        var check_review_professor_name = function() {
            if (($('#' + g.review_form_prefix + '_professor_name').val() as string).trim() === '') {
                g.can_smiley_be_clicked = false;
                if ((<HTMLSelectElement>document.getElementById('' + g.review_form_prefix + '_professor_opinion_category')).value === '1') {
                    unset_prof_opin_smiley();
                }
            } else {
                g.can_smiley_be_clicked = true;
            }
        };
        check_review_professor_name();
        $('#' + g.review_form_prefix + '_professor_name').keyup(check_review_professor_name);
        g.review_submission_ui_smiley_click = function() {
            if (g.can_smiley_be_clicked) {
                if ((<HTMLSelectElement>document.getElementById('' + g.review_form_prefix + '_professor_opinion_category')).value === '1') {
                    unset_prof_opin_smiley();
                } else {
                    set_prof_opin_smiley();
                }
            } else {
                alert('Please enter professor name before clicking on the smiley!');
            }
        };
    });

    var _review_form_textbox_len = [['_review_form_ui_written_review_word_count', 400, 10, (g.review_form_prefix + '_written_review')],
                                    ['_review_form_ui_exam_tips_word_count', 300, 0, (g.review_form_prefix + '_exam_tips')]];

    (<((id : string, f : (() => any)) => void)>Utils.executeIfHtmlElementWithIdExists)(_review_form_textbox_len[0][0].toString(), function() {
        var interactive_count = function(item : any) {
            var _func = function() {
                var cnt = ($('#' + item[3]).val() as string).toString().length;
                if (cnt <= item[1]) {
                    let reqd = item[1] - cnt;
                    $('#' + item[0]).html('(' + reqd + ')');
                    $('#' + item[0]).css('color', '#999');
                } else {
                    let reqd = cnt - item[1];
                    $('#' + item[0]).html('(-' + reqd + ')');
                    $('#' + item[0]).css('color', 'red');
                }
            };
            $('#' + item[3]).keyup(_func);
            _func();
        };
        let len = _review_form_textbox_len.length;
        for (let i = 0; i < len; i++) {
            var item = _review_form_textbox_len[i];
            interactive_count(item);
        }
    });
}
export default setupForm;
