
import BodyScriptActivator from "frontendframework/dist/javascripts/body_script_activator";
// import UserSessionController from "src/controllers/user_session_controller";

// This file connects all controllers to application

interface IRoute {
    activationIndex: string;
    action: ((htmlElement: HTMLElement) => void);
}

const processRoute = (route: IRoute) => {
    // console.info(route);
    BodyScriptActivator.getInstance().AddEntryToLookupTable(route.activationIndex, route.action);
};

const processRoutes = (routes: IRoute[]) => {
    for (let i = 0; i < routes.length; i++) {
        const route = routes[i];
        processRoute(route);
    }
};

export const setupRouting = () => {
    // TODO: Setup "controllers"

    const dataStructure: IRoute[] = [
        //{activationIndex: "#user-session", action: (htmlElement) => { const usc = new UserSessionController(); usc.createSession(htmlElement); }},
    ];

    processRoutes(dataStructure);
};
