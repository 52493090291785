/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

/////////////////
// Stylesheets //
/////////////////

require("../stylesheets/application.scss");


// require es6-promise
// TODO: Add me?

// require jquery3
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// require jquery_ujs
import Rails from "rails-ujs";
Rails.start();

// require jQuery-contextMenu
require(
    'imports-loader?define=>false,exports=>false,jQuery=>window.jQuery,this=>window!jquery-contextmenu/dist/jquery.contextMenu.js'
);

// require devbridge-autocomplete/src/jquery.autocomplete
require(
    'imports-loader?define=>false,exports=>false,jQuery=>window.jQuery,this=>window!devbridge-autocomplete/dist/jquery.autocomplete.js'
);

// require bootstrap.min
require(
    'imports-loader?define=>false,exports=>false,jQuery=>window.jQuery,this=>window!bootstrap/dist/js/bootstrap.js'
);


// require frontendframework/all

import Base from "frontendframework/dist/javascripts/base";
import {setup} from "frontendframework/dist/javascripts/pub_sub";
import Runtime from "frontendframework/dist/javascripts/runtime";
import BodyScriptActivator from "frontendframework/dist/javascripts/body_script_activator";

BodyScriptActivator.BODY_SCRIPT_ACTIVATION_SECTION_SELECTOR = '.fef-bsa';
BodyScriptActivator.BODY_SCRIPT_ACTIVATION_SECTION_DATASET_ACTIVATION_INDEX_KEY = 'key';

Base.getInstance(window);
setup();
Runtime.getInstance();

// require main

///////////////////////////
// Load main entry point //
///////////////////////////

require('../src/main.ts');


require(
    'imports-loader?define=>false,exports=>false,jQuery=>window.jQuery,this=>window!../src/google_analytics/google_analytics_initialization.js'
);

require(
    'imports-loader?define=>false,exports=>false,jQuery=>window.jQuery,this=>window!../src/google_analytics/google_analytics.turbolinks.coffee'
);
