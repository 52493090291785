
export let seeIfHTMLSelectElementContainsOption = function(select_box_id : string, option : any) {
    var exists = false;
    $('#' + select_box_id + ' option').each(function(this: HTMLElement) : false|void {
        if ((this as HTMLOptionElement).value === option) {
            exists = true;
            return false;
        }
    });
    return exists;
};

export let executeIfHtmlElementWithIdExists = function(id : string, f : (() => any)) {
    var elem = document.getElementById(id);
    if (elem != null) {
        f();
    }
};

export let executeIfHtmlElementWithIdDoesNotExist = function(id : string, f : (() => any)) {
    var elem = document.getElementById(id);
    if (elem == null) {
        f();
    }
};
